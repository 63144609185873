import React from "react";
import { Avatar, Button, Divider, Link } from "@mui/material";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { Source } from "../app/slice/chatFeedSlice";
import {validUrlOrNone} from "../util/helper";

type SourceViewProps = {
  sources: Array<Source>;
};

function SourceView({ sources }: SourceViewProps) {
  const extractImageURL = (sourceUrl: string) => {
      const url = validUrlOrNone(sourceUrl)
      if(url === null){
          return "https://convogridpublicassets.blob.core.windows.net/sources/unknown_source_link.png";
      }
      const youtubeWatchParam = url.host.endsWith("youtube.com") ? url.searchParams.get("v") || null : null;
      if (youtubeWatchParam) {
          return `http://img.youtube.com/vi/${youtubeWatchParam}/hqdefault.jpg`;
        }
      return "https://convogridpublicassets.blob.core.windows.net/sources/unknown_source_link.png";
  };

  const [expansion, setExpansion] = React.useState(false);

  return (
    <List
      sx={{ width: "100%", paddingTop: 0, paddingBottom: 0 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <Button
        size="small"
        onClick={() => setExpansion((val) => !val)}
        disableTouchRipple={true}
        style={{
          color: expansion ? "rgba(50,50,50,1)" : "rgba(148,148,148,1)",
          minHeight: 0,
          minWidth: 0,
          padding: 5,
          fontSize: "0.7em",
          textTransform: "none",
          backgroundColor: "transparent",
        }}
      >
        <TravelExploreIcon fontSize="small" /> &nbsp;Sources{" "}
        {expansion ? (
          <ArrowDropUpIcon fontSize="small" />
        ) : (
          <ArrowDropDownIcon fontSize="small" />
        )}
      </Button>
      <Collapse in={expansion} timeout="auto" unmountOnExit>
        <List disablePadding>
          {sources
            .map((source, index) => (
              <Link
                href={source.source}
                underline="none"
                target="_blank"
                rel="noopener"
                key={index}
              >
                <ListItem
                  style={{
                    background: "rgba(50,50,50,0.7)",
                    paddingBottom: 0,
                    paddingTop: 0,
                    paddingLeft: 10,
                    borderTopRightRadius: index === 0 ? 10 : 0,
                    borderTopLeftRadius: index === 0 ? 10 : 0,
                  }}
                >
                  <ListItemAvatar sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <Avatar
                      sx={{ borderRadius: 2, width: 50, height: 50 }}
                      src={extractImageURL(source.source)}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "0.7em",
                      color: "white",
                    }}
                    secondaryTypographyProps={{
                      fontSize: "0.6em",
                      color: "rgba(200,200,200,1)",
                      noWrap: true,
                    }}
                    primary={source.title}
                    secondary={source.source}
                  />
                </ListItem>
              </Link>
            ))
            .map((element, index) => (
              <React.Fragment key={index}>
                {!!index && <Divider />}
                {element}
              </React.Fragment>
            ))}
        </List>
      </Collapse>
    </List>
  );
}

export default SourceView;
