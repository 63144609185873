import React, {useEffect} from "react";

import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Canvas from "./Canvas";
import Zoom from '@mui/material/Zoom';
import { v4 as uuidv4 } from 'uuid';
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {loadBaseWidgetData, selectBaseWidgetData} from "./app/slice/baseWidgetSlice";
import {Theme, ThemeProvider} from "@mui/material/styles";
import {Badge, Card, ScopedCssBaseline} from "@mui/material";
import {ExtensionType} from "./util/extension.type";
import {CONVOCHAT_EXTENSION_ORIGINS, CONVOGRID_ORIGIN} from "./app/config/env";
import {
    BrowserMessageEventType,
    ChatEventType,
    ConvoEventType
} from "./app/dto";
import CardContent from "@mui/material/CardContent";
import MuiMarkdown from "./component/MuiMarkdown";
import {
    isResponse,
    selectChatFeedMessages,
} from "./app/slice/chatFeedSlice";
import SocketConnection from './app/services/socket'
import EvenHandler from './app/services/event'
import Auth, {UserType} from './app/services/auth'
import Global from "./app/services/global";

type AppProps = {
    theme: Theme;
    urlPath: string;
    webInstanceId: string;
    withDefault: boolean
};

function App({urlPath, theme, webInstanceId, withDefault}: AppProps) {

    const dispatch = useAppDispatch();
    const baseWidgetData = useAppSelector(selectBaseWidgetData);
    const chatFeedMessages = useAppSelector(selectChatFeedMessages);

    const [isChatPlaneVisible, setChatPlaneVisibility] = React.useState<boolean>(false);
    const [checked, setChecked] = React.useState<boolean>(false);
    const [notificationCount, setNotificationCount] = React.useState<number>(0);
    const [latestMessage, setLatestMessage] = React.useState<string>("");
    const [viewMessage, setViewMessage] = React.useState<boolean>(false);
    const containerRef = React.useRef<HTMLElement>(null);
    const avatarRef = React.useRef<HTMLDivElement>(null);
    let timeoutRef: NodeJS.Timeout | null = null

    //remove trailing slash
    const sanitizedUrlPath = urlPath.replace(/\/$/, "");

    const urlOrigin = new URL(sanitizedUrlPath).origin

    useEffect(() => {
        window.parent.postMessage({type: BrowserMessageEventType.HEALTH, isLoaded:"true", webInstanceId: webInstanceId}, urlOrigin);
    }, []);


    //post message to all parent windows for get their origin
    useEffect(() => {

        const controller = new AbortController;
        //receive origin from parent window
        window.addEventListener("message", (event: any) => {

            if(event.data.webInstanceId !== webInstanceId){
                console.log("webInstanceId was different. The message is ignored as it's not intended for this instance.")
                return;
            }

            if (event.data.type === BrowserMessageEventType.ORIGIN_ACK) {
                if (event.origin !== urlOrigin) {
                    controller.abort()
                    return
                }

                if (event.origin === CONVOGRID_ORIGIN) {
                    if(event.data.user){
                        Auth.setUser(UserType.ConvoUser, event.data.user, event.data.participantToken, event.data.participantId)
                    }

                    dispatch(loadBaseWidgetData({
                        withDefault:withDefault,
                        extensionType: ExtensionType.CommercialExtension,
                        urlPath: sanitizedUrlPath,
                        isConvoApp: true
                    }))
                    controller.abort()
                    return
                }

                if (CONVOCHAT_EXTENSION_ORIGINS.includes(event.origin)) {
                    dispatch(
                        loadBaseWidgetData({
                            withDefault:withDefault,
                            extensionType: ExtensionType.YouTube,
                            urlPath: sanitizedUrlPath,
                            isConvoApp: false
                        })
                    );
                } else {
                    dispatch(
                        loadBaseWidgetData({
                            withDefault:withDefault,
                            extensionType: ExtensionType.CommercialExtension,
                            urlPath: sanitizedUrlPath,
                            isConvoApp: false
                        })
                    );
                }

                controller.abort()
            }
        }, {signal: controller.signal});
        window.parent.postMessage({type: BrowserMessageEventType.GET_ORIGIN, webInstanceId,}, urlOrigin);
    }, []);

    useEffect(() => {
        if (timeoutRef) {
            clearTimeout(timeoutRef)
            timeoutRef = null
        }
        setChecked(false);
        setViewMessage(false);
        setNotificationCount(0)
    }, [isChatPlaneVisible]);

    useEffect(() => {

        if (chatFeedMessages.length > 0) {
            const message = chatFeedMessages[chatFeedMessages.length - 1]
            if (isResponse(message)) {
                if (timeoutRef) {
                    clearTimeout(timeoutRef)
                    timeoutRef = null
                    setNotificationCount(count => count + 1)
                    setChecked(false);
                }
                setLatestMessage(message.text)
                setChecked(true);
                timeoutRef = setTimeout(() => {
                    setChecked(false)
                }, 10000)
                setViewMessage(true)
            }
        }

    }, [chatFeedMessages.length]);


    useEffect(() => {
        if (baseWidgetData.isReady === true) {
            window.parent.postMessage(
                {
                    webInstanceId: webInstanceId,
                    type: BrowserMessageEventType.RESIZE,
                    component: (isChatPlaneVisible) ? "CHAT" : "BOT",
                    height: (isChatPlaneVisible) ? 'max(min(60vh,555px),225px)': 'max(min(16vh,100px),82px)',
                    width: (isChatPlaneVisible) ? 'min(max(20vw,330px),385px)': 'min(100px,27vw)'
                },
                urlOrigin
            );
        }
        if (baseWidgetData.isReady === false){
            window.parent.postMessage(
                {
                    webInstanceId: webInstanceId,
                    type: BrowserMessageEventType.RESIZE,
                    component: "NO_BOT"
                },
                urlOrigin
            );
        }
    }, [isChatPlaneVisible, baseWidgetData.isReady]);


    useEffect(() => {
        if (baseWidgetData.isReady && !isChatPlaneVisible) {
            window.parent.postMessage(
                {
                    webInstanceId: webInstanceId,
                    type: BrowserMessageEventType.RESIZE,
                    component: (viewMessage) ? "BOT_WITH_MESSAGE" : "BOT",
                    height: (viewMessage) ? (avatarRef.current?.offsetHeight || 0): 'max(min(16vh,100px),82px)',
                    width: (viewMessage) ? (avatarRef.current?.offsetWidth || 0): 'min(100px,27vw)'
                },
                urlOrigin
            );
        }
    }, [isChatPlaneVisible, viewMessage,baseWidgetData.isReady]);



    useEffect(() => {
        if (baseWidgetData.isReady === true) {
            const instanceId = uuidv4()
            Global.setInstanceId(instanceId)
            SocketConnection.configure(
                instanceId,
                baseWidgetData.identity.participantToken,
                baseWidgetData.botId,
                baseWidgetData.extensionType,
                dispatch
            )

            EvenHandler.handle(
                {
                    type: ConvoEventType.CHAT_EVENT,
                    eventId: uuidv4(),
                    channel:baseWidgetData.extensionType,
                    instanceId: instanceId,
                    botId: baseWidgetData.botId,
                    versionId:baseWidgetData.versionId,
                    chatEventType: ChatEventType.CHAT_LOADED,
                    referrer:urlPath
                }
            )
        }

    }, [baseWidgetData.isReady]);





    return (
        <ThemeProvider theme={theme}>
            <ScopedCssBaseline style={{padding: 0, margin: 0}}>
                {isChatPlaneVisible &&
                    <Grow
                        in={isChatPlaneVisible}
                        style={{transformBox: "border-box"}}
                        {...(isChatPlaneVisible ? {timeout: 1000} : {})}
                    >
                        <Paper
                            elevation={8}
                            style={{borderRadius: 12}}
                            sx={{
                                position: "absolute",
                                width: "calc(100vw - 23px)",
                                height: "calc(100vh - 23px)",
                            }}
                        >
                            <Canvas close={() => setChatPlaneVisibility(false)}/>
                        </Paper>
                    </Grow>
                }
                {!isChatPlaneVisible &&
                    <div
                        ref={avatarRef}
                        style={{
                            minHeight: 100,
                            right: '0vh',
                            bottom: "0vh",
                            position: "fixed",
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-start'
                        }}>
                        {viewMessage && (
                            <div style={{paddingRight: 90}}>
                                <Box sx={{width: 200, height: '60%', overflow: 'hidden'}} ref={containerRef}>
                                    <Slide in={checked} container={containerRef.current} direction={'left'}>
                                        <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                                            <Zoom in={checked} style={{transformOrigin: 'bottom'}} onExited={() => {
                                                if (!checked) {
                                                    setViewMessage(false)
                                                    setNotificationCount(count => count + 1)
                                                }
                                            }}>
                                                <Card
                                                    variant="outlined"
                                                    sx={{ml: 1}}
                                                    style={{
                                                        paddingTop: 10,
                                                        paddingBottom: 10,
                                                        background: "rgba(246,246,246,1)",
                                                        borderRadius: 10,
                                                    }}
                                                >
                                                    <CardContent
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: "20",
                                                            WebkitBoxOrient: "vertical",
                                                            paddingTop: 0,
                                                            marginTop: '-1em',
                                                            marginBottom: (latestMessage.length > 300) ? '0em' : '-1em',
                                                            paddingBottom: 0,
                                                            fontSize: "0.9em",
                                                            fontWeight: 400,
                                                            wordWrap: "break-word",
                                                            boxSizing: "content-box",
                                                        }}
                                                    >
                                                        <MuiMarkdown
                                                            markdownText={latestMessage}
                                                            markdownCustomCSS={{fontSize: "0.9em", lineHeight: "1.3"}}
                                                        />
                                                    </CardContent>
                                                </Card>
                                            </Zoom>
                                        </div>
                                    </Slide>
                                </Box>
                            </div>
                        )}

                        <Grow
                            in={!isChatPlaneVisible && baseWidgetData.isReady === true}
                            style={{
                                transformBox: "border-box",
                                marginRight:10,
                                marginBottom: 10,
                                alignContent: 'flex-end',
                                bottom: "0vh",
                                position: "fixed",
                            }}
                            {...(!isChatPlaneVisible && {timeout: 1000})}
                        >

                            <Button
                                variant="contained"
                                style={{borderRadius: 50, border: '4px solid', background: "rgb(38 40 46)"}}
                                onClick={() => setChatPlaneVisibility(true)}
                                sx={{
                                    padding: 0,
                                    position: "absolute",
                                    margin: 0

                                }}
                            >
                                <Badge color="primary" overlap="circular" badgeContent={notificationCount}>
                                    <Avatar
                                        sx={{
                                            width: 65,
                                            height: "auto",
                                        }}
                                        style={{aspectRatio: 1}}
                                        alt="botAvatarURL"
                                        src={baseWidgetData.isReady ? baseWidgetData.botAvatarURL : ''}
                                    />
                                </Badge>
                            </Button>
                        </Grow>
                    </div>
                }
            </ScopedCssBaseline>
        </ThemeProvider>
    );
}

export default App;
